import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';

export const recursiveGetSchoolHistory = createAsyncThunk(
  'schoolHistory/recursiveGetSchoolHistory',
  async ({ student, type, attempts = 0 }, { dispatch }) => {
    const MAX_ATTEMPTS = 12;
    if (attempts >= MAX_ATTEMPTS) {
      return { success: false, data: 'Exceeded maximum retry attempts.' };
    }

    try {
      const response = await api.get('academic-record', {
        params: { studentId: student.id, type },
      });
      const { data } = response.data;

      if (!Array.isArray(data) || !data[0]?.record?.length) {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        return await dispatch(
          recursiveGetSchoolHistory({ student, type, attempts: attempts + 1 })
        );
      }

      return {
        success: true,
        data: {
          ...data[0],
          record: data[0].record.sort((a, b) =>
            a.grade.name.localeCompare(b.grade.name)
          ),
        },
      };
    } catch (err) {
      return {
        success: false,
        data: err.response?.data || {
          message: 'An unexpected error occurred.',
        },
      };
    }
  }
);

export const getSchoolHistory = createAsyncThunk(
  'schoolHistory/getSchoolHistory',
  async ({ student, type }) => {
    try {
      const response = await api.get('academic-record', {
        params: {
          studentId: student.id,
          type,
        },
      });
      const { data } = await response.data;

      if (!data.length) {
        return {
          success: true,
          data: null,
        };
      }

      return {
        success: true,
        data: {
          ...data[0],
          record: data[0].record.sort((a, b) =>
            a.grade.name.localeCompare(b.grade.name)
          ),
        },
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const createSchoolHistory = createAsyncThunk(
  'schoolHistory/createSchoolHistory',
  async ({ student, type }) => {
    try {
      await api.post('academic-record', {
        studentId: student.id,
        type,
      });

      return { success: true };
    } catch (err) {
      console.error('create school history error');
      return { success: false };
    }
  }
);

export const updateSchoolHistory = createAsyncThunk(
  'schoolHistory/updateSchoolHistory',
  async (history) => {
    try {
      const response = await api.patch(`academic-record/${history.id}`, {
        ...history,
      });
      const data = await response.data;

      return {
        success: true,
        data: {
          ...data,
          record: data.record.sort((a, b) =>
            a.grade.name.localeCompare(b.grade.name)
          ),
        },
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

const schoolHistorySlice = createSlice({
  name: 'schoolHistory',
  initialState: {
    selectedSchoolHistory: null,
    selectedStudent: null,
    recordType: null,
    createLoading: false,
  },
  reducers: {
    setSliceValue(state, { payload }) {
      const { field, value } = payload;
      state[field] = value;
    },
  },
  extraReducers: {
    [getSchoolHistory.fulfilled]: (state, { payload }) => {
      if (payload.success && !!payload.data) {
        state.selectedSchoolHistory = payload.data;
      }
    },

    [recursiveGetSchoolHistory.pending]: (state) => {
      state.createLoading = true;
    },

    [recursiveGetSchoolHistory.fulfilled]: (state, { payload }) => {
      if (payload.data?.record?.length) {
        state.selectedSchoolHistory = payload.data;
        state.createLoading = false;
      }
    },

    [createSchoolHistory.pending]: (state) => {
      state.createLoading = true;
    },

    [updateSchoolHistory.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        state.selectedSchoolHistory = payload.data;
      }
    },
  },
});

export const { setSliceValue } = schoolHistorySlice.actions;

export default schoolHistorySlice.reducer;
