const reportPtMessages = {
  'report.access-report': 'Acessar relatório',

  'report.generate-report': 'Gerar relatório',
  'report.generating': 'Gerando...',

  'report.overview-demonstrative-report': 'Relatório demonstrativo',
  'report.overview-demonstrative': 'Demonstrativo Geral',

  'report.page-init-description': 'Primeiro, crie um relatório no botão acima.',

  'report.school-history': 'Histórico Escolar',
  'report.student-performance': 'Boletim',

  // api erros
  STUDENT_ENROLLMENT_NOT_FOUND:
    'Este aluno não possui registros no nível de ensino informado.',
};

export default reportPtMessages;
